import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// カラーテーマ追加
import colors from 'vuetify/lib/util/colors'
const commonTheme = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  //追加
  'price-up': colors.grey.darken4,
  'price-same': colors.grey.base,
  'price-down': colors.red,
}

let light = commonTheme
let dark = commonTheme

light['alt-light'] = colors.shades.white
light['alt-dark'] = '#f2f8f9'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: { light, dark },
  },
})
