<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import '@/scss/v2.5Pack.scss'
  export default {
    name: 'App',

    components: {
      //
    },

    data: () => ({
      //
    }),
  }
</script>
