import "./bootstrap";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

/* Element - Locale Setting */
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value), // i18n関数
})

Vue.config.productionTip = false;

import jquery from "jquery";
window.$ = jquery;

import RootMixin from "@/mixins/RootMixin";
import FramePlugin from "@/plugins/Frame";
Vue.use(FramePlugin);

new Vue({
  mixins: [RootMixin],
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
