export default {
  install: (Vue, options) => {
    Vue.mixin({
      methods: {
        /* Modal Selector */

        modalCheck(name) {
          return this.$root.Frame.modal === name ? true : false
        },
        modalShow(name) {
          this.$root.Frame.modal = name
        },
        modalClose() {
          this.$root.Frame.modal = null
        },

        /* Loading */

        loadingCheck(name) {
          return this.$root.Frame.loadings.some((item) => {
            return item === name
          })
        },
        loadingShow(name) {
          let idx = this.$root.Frame.loadings.findIndex((item) => item === name)
          if (idx < 0) {
            this.$root.Frame.loadings.push(name)
          }
        },
        loadingClose(name) {
          let idx = this.$root.Frame.loadings.findIndex((item) => item === name)
          if (idx > -1) {
            this.$root.Frame.loadings.splice(idx, 1)
          }
        },
      },
    })
  },
}
