import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '../404.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: '404',
      component: NotFound,
    },
    {
      path: '/:surveyId',
      component: () => import('../views/Preview'),
      children: [
        {
          path: '',
          redirect: 'welcome',
        },
        {
          path: 'register',
          component: () => import('../views/Preview/Register'),
        },
        {
          path: 'welcome',
          component: () => import('../views/Preview/FrontPage'),
        },
        {
          path: 'questions',
          component: () => import('../views/Preview/Questions'),
        },
        {
          path: 'complete',
          component: () => import('../views/Preview/AfterPage'),
        },
      ],
    },
  ],
})
