import lodash from 'lodash'
import axios from 'axios'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import ja from 'dayjs/locale/ja'

moment.tz.setDefault('Asia/Tokyo')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ja')

window._ = lodash
// window.$ = jquery
window.axios = axios
window.moment = moment
//window.cheerio = cheerio
window.dayjs = dayjs

/**
 * axios設定
 **/

axios.defaults.baseURL = 'https://review.repchecker.jp'
